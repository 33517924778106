<template>
  <v-container
    class="background"
    fluid
    fill-height
  >
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      @close="closeModal"
      @action="doAction"
    />
    <v-row class="left-right-container">
      <v-col class="left-area ma-4">
        <map-component
          mode="area"
        />
      </v-col>
      <v-col class="right-area ma-4">
        <v-row
          class="header"
          justify="space-around"
        >
          <img
            src="../../assets/image/icon/back.png"
            alt="뒤로가기"
            type="button"
            @click="$router.replace('/location-info')"
          >
          <span
            class="wall-title"
          > &nbsp;&nbsp;감속구간 </span>
          <v-spacer />
        </v-row>
        <v-row class="content-area">
          <template v-if="isMakingArea">
            <v-col
              class="btn-container"
              cols="12"
            >
              <v-select
                v-model="selectedSpeed"
                class="select-speed"
                :items="speedList"
                item-color="grey"
                placeholder="속도 선택"
                height="8vh"
                outlined
                dark
              />
              <v-btn
                class="btn-add"
                color="#298079"
                dark
                @click="reDraw"
              >
                다시 그리기
              </v-btn>
              <v-row class="btn-adding-row-container ma-0 pa-0">
                <v-col class="btn-adding-col-container ma-0 py-0">
                  <v-btn
                    class="btn-adding mt-8 mr-4"
                    color="#7c7c7c"
                    dark
                    @click="cancelAddArea"
                  >
                    취소
                  </v-btn>
                  <template v-if="canBeSaved&&selectedSpeed">
                    <v-btn
                      class="btn-adding mt-8 ml-4"
                      color="#00BFB4"
                      dark
                      @click="saveArea"
                    >
                      완료
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-btn
                      class="btn-adding mt-8 ml-4"
                      color="#00BFB4"
                      dark
                      disabled
                    >
                      완료
                    </v-btn>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <template v-else>
            <v-col
              class="btn-container"
              cols="12"
              align-self="center"
            >
              <v-btn
                class="btn-add"
                color="#00BFB4"
                dark
                @click="addArea"
              >
                추가
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <default-header/>
    </v-row>
  </v-container>
</template>

<script>
import MapComponent from '@/components/map/map.vue';
import DefaultModal from '@/components/modals/default_modal.vue';
import DefaultHeader from '@/components/header/default_header.vue';
import EventBus from '@/main.js';

export default {
  name: 'Area',
  components: {
    MapComponent,
    DefaultModal,
    DefaultHeader,
  },
  data() {
    return {
      isMakingArea: false,
      canBeSaved: false,
      selectedSpeed: null,
      showModal: false,
      modalState: {
        message: null,
        action: null
      },
      speedList: [
        '0.1 m/s',
        '0.2 m/s',
        '0.3 m/s',
        '0.4 m/s',
        '0.5 m/s',
        '0.6 m/s',
        '0.7 m/s',
        '0.8 m/s',
        '0.9 m/s',
        '1.0 m/s',
      ],
    }
  },
  created() {
    EventBus.$on('reDrawArea', ()=> {
      this.canBeSaved = true;
    });
    EventBus.$on('showRemoveAreaModal', this.confirmDeleteArea);
  },
  beforeDestroy() {
    EventBus.$off('reDrawArea');
    EventBus.$off('showRemoveAreaModal');
  },
  methods: {
    addArea() {
      EventBus.$emit('addArea');
      this.isMakingArea = true;
    },
    cancelAddArea() {
      this.selectedSpeed = null;
      EventBus.$emit('cancelAddArea');
      this.isMakingArea = false;
      this.canBeSaved = false;
    },
    reDraw() {
      EventBus.$emit('cancelAddArea');
      EventBus.$emit('addArea');
      this.canBeSaved = false;
    },
    saveArea() {
      const speed = this.selectedSpeed.slice(0,3);
      EventBus.$emit('saveArea', parseFloat(speed));
      this.selectedSpeed = null;
      this.canBeSaved = false;
      this.isMakingArea = false;
    },
    confirmDeleteArea() {
      this.modalState.message = '감속구간을 삭제하시겠습니까?';
      this.modalState.action = '삭제';
      this.showModal = true;
    },
    doAction() {
      EventBus.$emit('deleteArea');
      this.closeModal();
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.showModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  overflow: hidden;
  background-color: #393939;
  word-break: keep-all;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
}

.background > .left-right-container {
  height: 100vh;
}

.wall-title {
  color: #ffffff;
  font-size: 80px;
}

.content-area {
  height: 90%;
}

.btn-container {
  margin: 50px 0 0;
  height: 50% ;
  text-align: center;
}

.btn-add {
  height: 30% !important;
  width: 70%;
  border-radius: 10px;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.btn-adding-row-container {
  height: 30% ;
}

.btn-adding-col-container {
  height: 100% ;
  width: 70%;
}

.btn-adding {
  height: 100% !important;
  width: 34%;
  border-radius: 10px;
  font-size: 4rem !important;
  line-height: 117px;
  text-align: center;
}

.select-speed {
  width: 70%;
  margin: 0 0 20px 0 !important;
  left: 15%;
  background-color: black;
  border: 2px solid #545861;
}

@media (max-width: 1700px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .wall-title {
    font-size: 20px !important;
  }

  .btn-add {
    height: 40px !important;
    width: 100% !important;
    border-radius: 10px;
    font-size: 2rem !important;
  }

  .btn-adding-row-container {
    height: 40px ;
    width: 100%;
  }

  .btn-adding-col-container {
    padding: 0 !important;
    width: 70%;
  }

  .btn-adding {
    height: 100% !important;
    width: 45%;
    border-radius: 10px;
    font-size: 2rem !important;
  }
}

</style>

<style>
.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-input__slot {
  margin: 0;
  text-align: center;
  font-size: 3vw;
}

.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > #input-10 {
  max-height: none !important;
}

.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections > .v-select__selection--comma {
  overflow: inherit;
}

.background > .left-right-container > .right-area > .content-area > .btn-container > .v-input > .v-input__control > .v-text-field__details {
  display: none;
}



</style>
